/* You can add global styles to this file, and also import other style files */
// @import '~todomvc-common/base.css';
// @import '~todomvc-app-css/index.css';
@import url('https://fonts.googleapis.com/css?family=Bowlby+One+SC');


.todo-list li a.detail {
  position: absolute;
  top: 0;
  right: 40px;
  bottom: 0;
  width: 40px;
  height: 40px;
  margin: auto 0;
  line-height: 40px;
  font-size: 30px;
  color: #5DC1AE;
  margin-bottom: 11px;
  transition: color 0.2s ease-out;
  text-decoration: none;
}





input {
  font-size: 25px;
}

p {
  font-family: Orbitron;
}

.color-teal {
  // background-color: #097479;
  border: 1px solid #097479;
  background-color: black;
}

.color-red {
  background-color: red;
}

.color-blue {
  background-color: blue;
}

.color-purple {
  background-color: purple;
}

.clock-container{
  // font-size:120px;
  // /*line-height:25px;*/
  // color:white;
  // // height:100%;
  // // font-weight: 500;
  // text-align: center;
}

.clock, .clock2 {
  top:30%;
  // background-color: #202731;
  // width:50%;
  height:100%;
  // margin:10px auto;
  // padding:30px;
}
.clock div, .clock2 div {
  // display:inline-block;
  display: block;
  // background-color: #097479;
  width: 25%;
  height: 100%;
  text-align: center;
}
.clock2 {
  // background-color: #cdd5dc;
}

.time{
  width:100% !important;
  height: 128px !important;
  font-size: 100% !important;
}

.date {
  font-size: 70px !important;
  width: 100% !important;
  margin: 0 0 10px 0;
  padding: 0 0 15px 0;
}

:host{
  font-family: Orbitron;
}

body{
  font-family: Orbitron;
  line-height: inherit;
  font: 14px Orbitron !important;
  max-width: initial;
  background-color: #111111;
  color:#fff;
}

.white-font {
  color: #fff;
}

.width-100-percent {
  width: 100%;
}

.list-font-size {
  font-size: 26px;
}

.count-outputs {
  color: #fff;
  margin: 10px 20px 10px 0;
  font-size: 40px;
  top: 10px;
  position: relative;
}
.count-down-timer {
  color: #fff;
  padding: 10px;
  font-size: 12px;
  float: right;
  width: 155px;
}

.fhc-logo-mode {
  // background-image: url("/assets/fhc-logo.jpg");
  background-repeat:no-repeat;
  background-attachment:fixed;
  background-position:center; 
  background-size: contain;
  background-color: #fff; 
  width: 100%;
  height: 100%;
  color: blue;
}

html, body {
  height: 100%;
}

.button-right {
  float: right;
  padding: 25px;
  margin: 15px 10px 10px 0;
}



.scrollable-area {
  overflow-x: hidden;
  height: 200px;
  overflow-y: auto;
  margin: 5px 10px 10px;
}

.text-area {
  margin: 5px 10px 10px;
}

h4 {
  margin: 0px 0 0 5px;
}

// .highlight-row {
//   background-color: #097479;
// }

.current-location-title {
  margin: 0 0 0px;
  font-size: 20px;
  // background-color: #097479;
  padding: 5px;
}










iframe {
  display: block;       /* iframes are inline by default */
  background: #fff;
  border: none;         /* Reset default border */
  height: 88vh;        /* Viewport-relative units */
  width: 100vw;
}

.todoapp h1 {
  top: -170px;
  color: #097479;
}

.hide {
  display: none;
  // visibility: hidden;
}

.todoapp {
  margin: 0;
}

.normal-button  {
  font-weight: bold !important;
  background-color: #fff;
  color:#000 !important;
  padding: 0px !important;
  margin: 0px !important;
}

.money-button {
  height: 30px;
  width: 50px;
  margin: 0px 0 0 10px !important;
}

.wod-button {
  height: 30px;
  width: 80px;
  
  margin: 0px 10px 0px 0px !important;
  position: relative;
  top: -10px;
}

.money-textbox {
  width:113px;
}

.money-totals {
  font-weight: bold;
  font-size: 20px;
}

.money-wrapper {
  margin: 0 0 10px 10px;
}

.custom-select{
  // width:40px;
}

.wod {
  // font-weight: bold;
  text-align: center;
  font-family: 'Bowlby One SC', cursive;
  font-size: 60px;
  display: block;
}

.mod {
  // font-weight: bold;
  text-align: center;
  font-family: 'Bowlby One SC', cursive;
  font-size: 30px;
  display: block;
}



.white-link {
  color:#fff;
}

// .select-control .option,
select {
  background: rgba(0, 0, 0, 0.3);
  color: #fff;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);

  width: 97%;
  margin: 0 0px 10px 5px;
  font-size: 30px;
}

select option {
  margin: 40px;
  background: black;
  color: #fff;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
}

.links-for-center {
  float: right;
  margin: 0 0 0 10px;
  cursor: pointer;
}

// h1 {
//   text-align: center;
//   font-family: 'Bowlby One SC', cursive;
//   font-size: 25.5vw;
// }


button {
  color: #fff;
  padding: 25px;
  margin: 10px 10px 10px 0;
  /* background-color: #097479; */
}

.button-right-side {
  padding: 25px;
  margin: 0px;
  width: 100%;
}

.table-right-side {
  width: 100%;
  top: -1px;
  position: relative;
  margin: 0 0 0 0;

}

.table-right-side td {
  padding: 0 0px 10px 10px;
}

.top-buttons button {
  margin: 0px 10px 10px 0;
}

.right-info-panel {
  margin: 0 0 0 5px;
}

.button-pressed {
  border-width: 1px;
  background-color: #8d878d;
  /* #952095 */
}