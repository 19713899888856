// .flex-container {
//     display: flex;
//     width: 100%;
// }

// .flex-child {
//     flex: 3;
//     // border: 2px solid yellow;
//     margin-right: 10px;
// }

// .flex-child-2 {
//     flex: 4;
//     // border: 2px solid yellow;
//     // margin-right: 10px;
// }

// .flex-child-3 {
//     flex: 2;
//     // border: 2px solid yellow;
// }

// .flex-child:first-child {
//     margin-right: 10px;
// }

.header { grid-area: header; }
.menu { grid-area: menu; }
.main { grid-area: main; }
.right { grid-area: right; }
.footer { grid-area: footer; }

.grid-container {
  display: grid;
  grid-template-areas:
    'header header header header header header'
    'menu main main main main right'
    'footer footer footer footer footer footer';
  gap: 10px;
//   background-color: #2196F3;
//   padding: 10px;
}

.grid-container > div {
//   background-color: rgba(255, 255, 255, 0.8);
//   text-align: center;
//   padding: 20px 0;
//   font-size: 30px;
}



button {
    color: #fff;
    padding: 25px;
    margin: 10px 10px 10px 0;
    /* background-color: #097479; */
}

.button-right-side {
    padding: 25px;
    margin: 0px;
    width: 100%;
}

.table-right-side {
    width: 100%;
    top: -1px;
    position: relative;
    margin: 0 0 0 0;

}

.table-right-side td {
    padding: 0 0px 10px 10px;
}

.top-buttons button {
    margin: 0px 10px 10px 0;
}

.right-info-panel {
    margin: 0 0 0 5px;
}

.button-pressed {
    border-width: 1px;
    background-color: #8d878d;
    /* #952095 */
}