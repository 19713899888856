/* App.scss */

// body {
//     font-family: Arial, sans-serif;
//     background-color: #f4f4f4;
//     margin: 0;
//     padding: 0;
//   }
// .container {
//   display: flex;
//   /* Use flexbox for layout */
//   align-items: center;
//   /* Center vertically */
// }

// .title {
//   font-weight: bold;
// }

// .textbox {
//   // padding: 5px;
// }

// .textbox input[type="text"] {
//   width: 100%;
//   padding: 0;
//   margin: 0;
// }

// .button {
//   width: 100%;
//   /* Let this column take space */
// }

// h1 {
//   text-align: center;
//   margin-top: 20px;
//   color: #333;
// }

// .container {
//   max-width: 400px;
//   margin: 0 auto;
//   background-color: #fff;
//   padding: 20px;
//   border-radius: 5px;
//   box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
// }

// input[type="text"] {
//   width: 100%;
//   padding: 10px;
//   margin-bottom: 10px;
//   border: 1px solid #ccc;
//   border-radius: 5px;
// }

ul {
  list-style: none;
  padding: 0;
}

li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10px 10px 10px;
  border-bottom: 1px solid #ccc;

  &:last-child {
    border-bottom: none;
  }

  span {
    flex: 1;
    cursor: pointer;

    &.completed {
      text-decoration: line-through;
    }
  }

  button {
    background-color: #ff6347;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
  }
}

// .row-container {
//   display: grid;
//   grid-template-columns: 36px 151px 52px 147px 78px; // Set the desired widths for each div
//   gap: 10px; // Adjust the gap as needed
//   padding: 10px;
// }

// .row-div {
//   background-color: #f0f0f0;
//   padding: 10px;
//   border: 1px solid #ccc;
//   border-radius: 5px;
//   text-align: center;
//   font-size: 16px;
//   // Add more styling as needed
// }


// display: grid;
//   grid-template:
//     'header header header header header header'
//     'menu main main main right right'
//     'menu footer footer footer footer footer';



// .row-div {
//   background-color: #f0f0f0;
//   padding: 10px;
//   border: 1px solid #ccc;
//   border-radius: 5px;
//   text-align: center;
//   font-size: 16px;
//   // Add more styling as needed
// }


// display: grid;
//   grid-template:
//     'header header header header header header'
//     'menu main main main right right'
//     'menu footer footer footer footer footer';


// .todo-container {
//   display: flex;
//   align-items: center;
//   gap: 10px; // Adjust the gap as needed
//   padding: 10px;
// }

// .title, .priority, .button {
//   flex: 1;
// }

// .textbox {
//   flex: 2;
//   input {
//     width: 100%;
//   }
// }

// .button button {
//   // background-color: #0074d9;
//   // color: #fff;
//   // border: none;
//   // padding: 10px 20px;
//   // border-radius: 5px;
//   // cursor: pointer;

//   background-color: #ff6347;
//   color: #fff;
//   border: none;
//   border-radius: 5px;
//   padding: 5px 10px;
//   cursor: pointer;
// }

// .button button:hover {
//   background-color: #0056b3;
// }

// .priority-text-box {
//   width: 100%;
// }

// .text-text-box {
//   width: 100%;
// }


// .todo-container {
//   display: grid;
//   grid-template-columns: 36px 151px 52px 147px 78px; // Set the desired widths for each div
//   gap: 10px; // Adjust the gap as needed
//   padding: 10px;
// }

.todo-form {
  display: grid;
  grid-template-columns: 1fr auto 1fr 75px 1fr; // Create a single row grid
  align-items: center; // Vertically center the items
  gap: 10px; // Adjust the gap as needed
  padding: 10px;

  label,
  input,
  button {
    padding: 10px;
  }

  button {
    background-color: #0074d9;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }

  button:hover {
    background-color: #0056b3;
  }
}